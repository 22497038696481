import 'lazysizes'
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks'
import 'lazysizes/plugins/print/ls.print'
import 'iconify-icon'
import 'js-cookie'
import '@hotwired/turbo-rails'

import './controllers/index'
// NOTE: Turboはimportしてトランスパイルされるだけで、旧デザインでturboが有効になるようなので分けておく
import './controllers/index_v2'

