import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["overlay", "modal", "closeLink"]

  declare readonly overlayTarget: HTMLElement
  declare readonly modalTarget: HTMLElement
  declare readonly closeLinkTarget: HTMLElement

  open(event: Event) {
    event.preventDefault()

    this.overlayTarget.classList.add('show')
    this.modalTarget.classList.add('show')
    document.body.classList.add('modal-open')

  }

  close(event: Event) {
    event.preventDefault()

    this.overlayTarget.classList.remove('show')
    this.modalTarget.classList.remove('show')
    document.body.classList.remove('modal-open')
  }
}
