import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["dropdown"]

  declare readonly dropdownTarget: HTMLSelectElement

  updateClass(): void {
    this.dropdownTarget.classList.toggle("is-selected", this.dropdownTarget.value !== "")
  }
}
