import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"

type StationOption = {
  code: string
  nameJa: string
  nameEn: string
}

declare global {
  interface HTMLSelectElement {
    tomselect: TomSelect;
  }
}

export default class extends Controller {
  static targets = ['select']
  static values = { apiKey: String, language: String }

  declare readonly selectTarget: HTMLSelectElement
  declare readonly apiKeyValue: string
  declare readonly languageValue: string

  connect(): void {
    new TomSelect(this.selectTarget, {
      valueField: 'nameJa',
      searchField: [], // ひらがな・英語での入力でもオートコンプリートできるように
      onType: (): void => { this.selectTarget.tomselect.clear() }, // ２つ以上駅名を入力できないように
      optionClass: 'commute-search-form__station-option',
      itemClass: 'commute-search-form__station-item',
      loadThrottle: 500,
      render: {
        option: (item: StationOption, escape: (_value: string) => string) => {
          return this.renderStationOption(item, escape)
        },
        item: (item: StationOption, escape: (_value: string) => string) => {
          return this.renderStationOption(item, escape)
        }
      },
      load: (query: string, callback: (_options?: StationOption[]) => void): void => {
        if (!query.length) return callback()

        this.selectTarget.tomselect.clearOptions() // 以前にAJAXして取得したオプションが残ってしまうので

        const url = `/search/search_station?name=` + encodeURIComponent(query)

        fetch(url)
          .then(response => response.json())
          .then(json => {
            if (json) {
              callback(json)
            } else {
              callback()
            }
          })
          .catch(error => {
            console.error(error)
            callback()
          })
      }
    })
  }

  private renderStationOption(item: StationOption, escape: (_value: string) => string): string {
    const nameJa = escape(item.nameJa)
    const nameEn = escape(item.nameEn)

    if (this.languageValue === 'ja') {
      return `
        <div class="ts-option">
          <span class="ts-option-ja">${nameJa}</span>
        </div>
      `
    } else {
      // 英語ページのみ「日本語 (英語)」を表示
      return `
        <div class="ts-option">
          <span class="ts-option-ja">${nameJa}</span>
          <span class="ts-option-en">${nameEn}</span>
        </div>
      `
    }
  }
}
