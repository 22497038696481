import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tooltip"]

  declare tooltipTarget: HTMLElement

  connect() {
    document.addEventListener("click", this._handleOutsideClick.bind(this))
  }

  disconnect() {
    document.removeEventListener("click", this._handleOutsideClick.bind(this))
  }

  toggle(event: Event) {
    // クリックイベントが外部伝播しないようにストップ（任意）
    event.stopPropagation()

    if (this.tooltipTarget.classList.contains("show")) {
      this.hide()
    } else {
      this.show()
    }
  }

  show() {
    this.tooltipTarget.classList.add("show")
  }

  hide() {
    this.tooltipTarget.classList.remove("show")
  }

  private _handleOutsideClick(event: Event) {
    const target = event.target as HTMLElement
    // ツールチップ表示中で、かつクリック箇所がコントローラ管理要素外の場合、ツールチップを閉じる
    if (this.tooltipTarget.classList.contains("show") && !this.element.contains(target)) {
      this.hide()
    }
  }
}
