import { Controller } from "@hotwired/stimulus"
import { Fancybox } from "@fancyapps/ui"

export default class extends Controller {
  connect() {
    Fancybox.bind(this.element, '[data-fancybox]', {
      Toolbar: true,
      loop: true,
      Thumbs: false,
      animationEffect: "zoom",
      compact: false
    })
  }
}
