import { Controller } from "@hotwired/stimulus"

declare global {
  interface Window {
    dataLayer: Array<{
      event: string;
      name?: string;
      email?: string;
      phone?: string;
      [key: string]: unknown;
    }>;
  }
}

interface ContactFormEvent extends Event {
  params: {
    type: string;
  }
}

export default class extends Controller {
  static targets = ["form", "text", "field", "submitButton"]
  static values = { locale: String, submitting: Boolean }

  declare readonly formTarget: HTMLFormElement
  declare readonly textTargets: HTMLElement[]
  declare readonly submitButtonTarget: HTMLButtonElement
  declare readonly fieldTargets: HTMLElement[]
  declare readonly localeValue: string
  declare timer: ReturnType<typeof setTimeout>
  declare submittingValue: boolean
  formType: string = "none"

  connect() {
    const params = new URLSearchParams(window.location.search)
    const contactKindParams = params.get('contact_kind')
    if (contactKindParams) {
      this.formType = contactKindParams
    }
    this.updateForm()
  }

  switchForm(event: ContactFormEvent) {
    event.preventDefault()

    if (event.params) {
      this.formType = event.params.type
    }

    this.updateForm()
  }

  disconnect() {
    clearTimeout(this.timer)
  }

  updateForm() {
    this.fieldTargets.forEach(target => {
      if (target.dataset.formTypes && target.dataset.formTypes.includes(this.formType)) {
        target.querySelectorAll('input, textarea, select').forEach(element => {
          (element as HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement).disabled = false
        })
        target.classList.remove("is-hidden")
      } else {
        target.querySelectorAll('input, textarea, select').forEach(element => {
          (element as HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement).disabled = true
        })
        target.classList.add("is-hidden")
      }
    })

    this.textTargets.forEach(target => {
      if (target.dataset.formTypes && target.dataset.formTypes.includes(this.formType)) {
        target.classList.remove("is-hidden")
      } else {
        target.classList.add("is-hidden")
      }
    })
  }

  sendKarteEventsOnValidForm(event: Event) {
    if (this.submittingValue) {
      event.preventDefault()
      return
    }

    if (this.validation(event)) {
      this.setKarteIdentifyTag()
      this.setKarteContactTag()
      this.setTimestamp()

      this.submittingValue = true
      this.submitButtonTarget.disabled = true
      this._startResetTimer()
    }
  }

  validation(event: Event) {
    const nameInput = document.getElementById("name") as HTMLInputElement
    const emailInput = document.getElementById("mail") as HTMLInputElement
    if (nameInput && nameInput.value === "") {
      alert(this.localeValue === "ja" ? "お名前は必須項目です" : "name is a required field")
      event.preventDefault()
      return
    }
    if (emailInput && emailInput.value === "") {
      alert(this.localeValue === "ja" ? "メールアドレスは必須項目です" : "email is a required field")
      event.preventDefault()
      return
    }
    return true
  }

  setKarteIdentifyTag() {
    const nameInput = this.formTarget.querySelector('#name') as HTMLInputElement | null
    const emailInput = this.formTarget.querySelector('#mail') as HTMLInputElement | null
    const phoneInput = this.formTarget.querySelector('#tel') as HTMLInputElement | null

    if (!nameInput || !emailInput || !phoneInput) {
      return
    }

    window.dataLayer.push({
      event: "karte_identify",
      name: nameInput.value,
      email: emailInput.value,
      phone: phoneInput.value,
    })
  }

  setKarteContactTag() {
    const form = this.formTarget
    if (!form) return

    const buildSelect = form.querySelector('#build') as HTMLSelectElement | null
    const preferredTimingSelect = form.querySelector('#preferred_timing') as HTMLSelectElement | null
    const furnitureSelect = form.querySelector('#furniture') as HTMLSelectElement | null
    const preferredPeriodSelect = form.querySelector('#preferred_period') as HTMLSelectElement | null
    const budgetSelect = form.querySelector('#budget') as HTMLSelectElement | null

    if (!buildSelect || !preferredTimingSelect || !furnitureSelect || !preferredPeriodSelect || !budgetSelect) {
      return
    }

    const buildSelectedOption = buildSelect.selectedOptions[0]
    const item_id = buildSelectedOption?.getAttribute('building-id') || ''
    const item_name = buildSelectedOption?.value || ''
    const preferred_timing = preferredTimingSelect.value
    const furniture = furnitureSelect.value
    const preferred_period = preferredPeriodSelect.value
    const budget = budgetSelect.value

    window.dataLayer.push({
      event: "karte_contact",
      item_id,
      item_name,
      preferred_timing,
      furniture,
      preferred_period,
      budget,
    })
  }

  _reset() {
    this.submittingValue = false
    this.submitButtonTarget.disabled = false
  }

  _startResetTimer() {
    this.timer = setTimeout(() => {
      this._reset()
    }, 60000)
  }

  setTimestamp() {
    const date = new Date()
    const timestampInput = document.getElementById('timestamp')
    if (timestampInput instanceof HTMLInputElement) {
      timestampInput.value = date.getTime().toString()
    }
  }
}
