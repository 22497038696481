import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    videoId: String,
  }
  static targets = ["player"]

  declare readonly playerTarget: HTMLElement
  declare readonly videoIdValue: string
  private player!: YT.Player

  connect() {
    if (!window.YT) {
      this.loadYouTubeApi()
    } else {
      this.initializePlayer()
    }
  }

  disconnect() {
    if (this.player) {
      this.player.destroy()
    }
  }

  private loadYouTubeApi() {
    const script = document.createElement("script")
    script.src = "https://www.youtube.com/iframe_api"
    script.onload = () => {
      (window as any).onYouTubeIframeAPIReady = this.initializePlayer.bind(this)
    }
    document.head.appendChild(script)
  }

  private initializePlayer() {
    this.player = new YT.Player(this.playerTarget, {
      videoId: this.videoIdValue,
      playerVars: {
        loop: 1,
        playlist: this.videoIdValue,
        controls: 0,
        autoplay: 1,
        start: 5,
      },
      events: {
        onReady: this.onPlayerReady.bind(this),
        // onStateChange: this.onPlayerStateChange.bind(this),
      },
    })
  }

  private onPlayerReady(event: YT.PlayerEvent) {
    event.target.mute()
    event.target.playVideo()
  }
}
