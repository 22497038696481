import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["sidemenu", "overlay"]

  declare sidemenuTarget: HTMLElement
  declare overlayTarget: HTMLElement

  open() {
    this.sidemenuTarget.classList.add('show')
    this.overlayTarget.classList.add('show')
  }

  close() {
    this.sidemenuTarget.classList.remove('show')
    this.overlayTarget.classList.remove('show')
  }
}

