import { Controller } from "@hotwired/stimulus"
import Swiper from 'swiper'
import { Navigation, Pagination, Thumbs } from 'swiper/modules'
import type { SwiperOptions } from 'swiper/types'

export default class extends Controller {
  static values = { slidesPerView: { type: Number, default: 1 }, options: { type: Object, default: {} } }
  static targets = ["slider", "thumbsSlider", "nextButton", "prevButton", "pagination"]

  declare readonly sliderTarget: HTMLElement
  declare readonly thumbsSliderTarget: HTMLElement
  declare readonly nextButtonTarget: HTMLElement
  declare readonly prevButtonTarget: HTMLElement
  declare readonly paginationTarget: HTMLElement
  declare readonly slidesPerViewValue: number
  declare readonly optionsValue: SwiperOptions
  declare hasPaginationTarget: boolean
  declare hasNextButtonTarget: boolean
  declare hasPrevButtonTarget: boolean
  declare hasThumbsSliderTarget: boolean
  declare hasOptionsValue: boolean

  connect() {
    const defaultOptions: SwiperOptions = {
      modules: [Navigation, Pagination, Thumbs],
      loop: true,
      slidesPerView: this.slidesPerViewValue,
      spaceBetween: 6,
    }

    const additionalOptions: SwiperOptions = this.hasOptionsValue ? this.optionsValue : {}

    const swiperOptions: SwiperOptions = {
      ...defaultOptions,
      ...additionalOptions,
    }

    if (this.hasThumbsSliderTarget) {
      const thumbsSlider = new Swiper(this.thumbsSliderTarget, {
        loop: false,
        slidesPerView: 4,
        spaceBetween: 8,
        freeMode: true,
        watchSlidesProgress: true,
      })
      swiperOptions.thumbs = {
        swiper: thumbsSlider,
      }
    }

    if (this.hasPaginationTarget) {
      swiperOptions.pagination = {
        el: this.paginationTarget,
        clickable: true,
      }
    }

    if (this.hasNextButtonTarget && this.hasPrevButtonTarget) {
      swiperOptions.navigation = {
        nextEl: this.nextButtonTarget,
        prevEl: this.prevButtonTarget,
      }
    }

    new Swiper(this.sliderTarget, swiperOptions)
  }
}
