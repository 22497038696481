import { Building } from "@/controllers/search/buildings_search_controller"

export function createPopupHtml(building: Building, language: string): string {
  const {
    slug,
    name,
    keyPhrase,
    imageUrl,
    buildingUrl,
    buildingTags,
    minRent
  } = building

  const tagsContent = buildingTags
    .map(tag => `<div class="search__popup-tag">${tag}</div>`)
    .join('')

  const minRentContent = minRent ?
    `
          <div class="search__popup-rent">
            <div class="search__popup-rent-label">${language == 'ja' ? "2年プラン賃料" : "Rent"}</div>
            <div class="search__popup-rent-text">¥${minRent.toLocaleString()}~</div>
          </div>
        `
    :
    `
        <div class="search__popup-novacancy">${language == 'ja' ? "現在満室です。詳細はお問い合わせください。" : "No vacancies available. Please contact us for more information"}</div>
      `

  return `
      <a class="search__popup-container" href="${buildingUrl}">
          <div class="search__popup-image-wrapper">
            <img class="search__popup-image" alt="${name}" src="${imageUrl}" loading="lazy">
            <button class="search__popup-favorite-buttons" data-controller="favorite" data-favorite-building-id-value="${slug}">
              <iconify-icon class="like-button js-like-button-${slug}" data-action="click->favorite#like" icon="mdi:heart"></iconify-icon>
              <iconify-icon class="unlike-button js-unlike-button-${slug}" data-action="click->favorite#unlike" icon="mdi:heart"></iconify-icon>
            </button>
          </div>
        <div class="search__popup-content">
          <div class="search__popup-text">
            <div class="search__popup-text-title">${name}</div>
            <p class="search__popup-text-description">${keyPhrase}</p>
          </div>
          <div class="search__popup-tags">${tagsContent}</div>
          ${minRentContent}
        </div>
      </a>
  `
}
