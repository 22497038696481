import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"
import Cookies from "js-cookie"

export default class extends Controller {
  static values = {
    buildingId: String,
    url: String
  }

  declare readonly buildingIdValue: string
  declare readonly urlValue: string

  unlike(event: Event) {
    event.preventDefault()
    event.stopPropagation()

    let favoriteCookieValues = this.parseCurrentFavoriteCookies()
    favoriteCookieValues = favoriteCookieValues.filter(id => id !== this.buildingIdValue)
    Cookies.set("favorite", favoriteCookieValues.join(','), { expires: 90, path: '/' })

    // NOTE: 画面上からも削除するためにTurboFrameで書き換えている
    Turbo.visit(this.urlValue, { frame: "items" })
  }

  parseCurrentFavoriteCookies() {
    const favoriteCookiesRaw = Cookies.get("favorite")
    return favoriteCookiesRaw ? favoriteCookiesRaw.split(',').map(id => id.trim()) : []
  }
}
