import { Controller } from "@hotwired/stimulus"
import BuildingsSearchController from "./buildings_search_controller"

export default class extends Controller {
  static targets = [
    'station',
    'minutes',
    'count',
  ]
  static outlets = [
    "buildings-search",
  ]

  declare readonly stationTarget: HTMLInputElement
  declare readonly minutesTarget: HTMLInputElement
  declare readonly countTarget: HTMLElement
  declare readonly buildingsSearchOutlet: BuildingsSearchController

  // NOTE: 検索結果の件数を「さらに条件を指定する」モーダル内にも反映するための処理
  countTargetConnected(element: HTMLElement) {
    const targetElements = document.querySelectorAll(".js-buildings-count")
    targetElements.forEach((targetElement) => {
      targetElement.textContent = element.textContent
    })
  }

  updateCount() {
    if (this.stationTarget.value === "" || this.minutesTarget.value === "") {
      this.renderCount(0)
      return
    }
    this.buildingsSearchOutlet.updateCount()
  }

  renderCount(count: number) {
    this.countTarget.textContent = count.toString()
  }
}
