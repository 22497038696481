import { Controller } from "@hotwired/stimulus"
import Swiper from 'swiper'
import { Navigation } from 'swiper/modules'
import type { SwiperOptions } from 'swiper/types'

const events = [
  // NOTE: 下の地図がズームしないように
  "dblclick",
  // NOTE: スワイプで下の地図が動かないように
  "touchstart", "touchmove", "touchend",
]

export default class extends Controller {
  static targets = ["slider", "nextButton", "prevButton"]

  declare readonly sliderTarget: HTMLElement
  declare readonly nextButtonTarget: HTMLElement
  declare readonly prevButtonTarget: HTMLElement
  declare hasNextButtonTarget: boolean
  declare hasPrevButtonTarget: boolean
  declare swiper: Swiper

  connect() {
    events.forEach(event => {
      this.element.addEventListener(event, this.stopPropagation, true)
    })

    const defaultOptions: SwiperOptions = {
      modules: [Navigation],
      loop: false,
      slidesPerView: 1,
      breakpoints: {
        1024: {
          slidesPerView: 2,
          spaceBetween: 10,
        },
      },
    }

    const swiperOptions: SwiperOptions = {
      ...defaultOptions,
    }

    if (this.hasNextButtonTarget && this.hasPrevButtonTarget) {
      swiperOptions.navigation = {
        nextEl: this.nextButtonTarget,
        prevEl: this.prevButtonTarget,
      }
    }

    this.swiper = new Swiper(this.sliderTarget, swiperOptions)
  }

  disconnect() {
    events.forEach(event => {
      this.element.removeEventListener(event, this.stopPropagation, true)
    })
  }

  next(event: Event) {
    event.stopPropagation() // NOTE: ポップアップが閉じられないように
  }

  prev(event: Event) {
    event.stopPropagation() // NOTE: ポップアップが閉じられないように
  }

  stopPropagation(event: Event) {
    event.stopPropagation()
  }
}
